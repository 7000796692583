import React, { useState, useEffect } from "react";
import { flattenPdf } from "../pdfUtils";
import Message from "./Message";

const Box = () => {
  const [fileSelected, setFileSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [operationComplete, setOperationComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      if (e.dataTransfer.files[0].type !== "application/pdf") {
        setErrorMessage("Please drop a PDF file.");
        return;
      }
      setFileSelected(true);
      setSelectedFile(e.dataTransfer.files[0]);
      setFileName(e.dataTransfer.files[0].name);
      setErrorMessage("");
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setFileSelected(true);
      setSelectedFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    } else {
      setFileSelected(false);
      setSelectedFile(null);
      setFileName("");
    }
  };

  const handleRunClick = async () => {
    if (!selectedFile) return;

    const reader = new FileReader();
    reader.onload = async (event) => {
      const sourcePdfArrayBuffer = event.target.result;
      const sourcePdfUint8Array = new Uint8Array(sourcePdfArrayBuffer);
      const flattenedPdfBytes = await flattenPdf(sourcePdfUint8Array);

      // Handle the flattened PDF bytes, e.g., download the flattened PDF
      const blob = new Blob([flattenedPdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `flattened_${fileName}`;
      link.click();
      URL.revokeObjectURL(link.href);
      setOperationComplete(true);
      setTimeout(() => {
        setOperationComplete(false);
        setFileSelected(false);
        setSelectedFile(null);
        setFileName("");
      });
    };
    reader.readAsArrayBuffer(selectedFile);
  };

  const handleErrorMessageClose = () => {
    setErrorMessage("");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    const dropZone = document.getElementById("drop-zone");

    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    if (dropZone) {
      dropZone.addEventListener("dragover", handleDragOver);
      dropZone.addEventListener("drop", handleDrop);
    }

    return () => {
      if (dropZone) {
        dropZone.removeEventListener("dragover", handleDragOver);
        dropZone.removeEventListener("drop", handleDrop);
      }
    };
  }, []);

  return (
    <div className="box-container is-flex is-align-items-center">
    <div className="column is-half is-offset-one-quarter">
      {errorMessage && (
        <Message
          type="error"
          message={errorMessage}
          onClose={handleErrorMessageClose}
        />
      )}
      <div className="box" role="region" data-testid="drop-box">
        <div className="is-flex is-flex-direction-column is-align-items-center">
          <div
            className="drop-zone has-text-centered py-5"
            id="drop-zone"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={() => document.getElementById('file-select').click()}
            style={{ border: '2px dashed #dbdbdb', borderRadius: '5px', cursor: 'pointer' }}
          >
            {fileSelected ? (
              <div id="file-info">
                <i className="fas fa-file-pdf"></i> {selectedFile?.name}
              </div>
            ) : (
              <div id="select-message">
                Drop PDF file here or click to select
              </div>
            )}
          </div>

          <input
            type="file"
            className="file-input"
            id="file-select"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <button
            id="run-button"
            className="button is-medium is-fullwidth is-primary"
            onClick={handleRunClick}
            disabled={!fileSelected || operationComplete}
          >
            Run
          </button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Box;
