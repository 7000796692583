import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Box from './components/Box';
import Faqs from './components/Faqs';
import About from './components/About';
import SiteLogo from './components/SiteLogo';
import './scss/main.scss';

function App() {
  return (
    <Router>
      <div className="App is-flex is-flex-direction-column">
        <Navbar />
        <div className="columns is-centered is-vcentered is-mobile">
          <div className="column is-narrow">
            <SiteLogo />
          </div>
        </div>
        <Routes>
          <Route exact path="/" element={
            <div className="container">
              <div className="columns">
              <div className="box">
                <div className="content is-medium">
                  <p className="has-text-justified">
                  Fltnr is a secure, client-side PDF flattening tool specifically
                  designed for Remote Online Notaries and professionals who require a
                  fast, efficient, and secure way to handle complex PDF documents. With
                  just a few clicks, Fltnr streamlines your document workflow by
                  eliminating layers and making PDFs easier to work with, while
                  ensuring complete privacy and security throughout the process.
                  </p>
                  <p className='has-text-centered'>
                    Start using it now, just drag and drop your PDF into the box below and click Run!
                  </p>
                </div>
              </div>
              </div>
              <Box role="box"/>
              <br /><br />
            </div>} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/about" element={<About />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );

}

export default App;
