import React from 'react';

const faqs = () => {
    return (
        <div className="container">
          <section className="section">
            <h1 className="title">Frequently Asked Questions</h1>
            <hr />
            <div className="content">
              <h2>What is Fltnr?</h2>
              <p>Fltnr is a web application that allows you to flatten PDF files.</p>
    
              <h2>How does Fltnr work?</h2>
              <p>Fltnr works by taking a PDF file that you upload, and removing any interactive form fields, comments, and annotations from the file, resulting in a flattened version of the PDF.</p>
    
              <h2>What happens to my original PDF file after I use Fltnr to flatten it?</h2>
              <p>Your original PDF file remains unchanged, and a new flattened PDF file is generated and available for download.</p>
    
              <h2>What file formats can I save the flattened PDF as using Fltnr?</h2>
              <p>Currently, Fltnr only allows you to save the flattened PDF file as a PDF file.</p>
    
              <h2>Is there a limit to the size of PDF files that Fltnr can handle?</h2>
              <p>Yes, there is a limit of 25 MB per PDF file.</p>
    
              <h2>How secure is my PDF file when I upload it to Fltnr?</h2>
              <p>Your PDF file is securely uploaded and processed by Fltnr, and is deleted from our servers immediately after processing is complete.</p>
    
              <h2>Can I use Fltnr on my mobile device?</h2>
              <p>Yes, Fltnr is a web application and can be accessed from any device with a web browser.</p>
    
              <h2>What should I do if I encounter an error while using Fltnr?</h2>
              <p>If you encounter an error while using Fltnr, please contact our support team for assistance.</p>
    
              <h2>How do I contact support if I have an issue with Fltnr?</h2>
              <p>You can contact our support team by emailing support@fltnr.com.</p>
    
              <h2>Can I share the flattened PDF file with others using Fltnr?</h2>
              <p>Yes, you can share the flattened PDF file with others by downloading it and sharing it as you would any other file.</p>
            </div>
          </section>
        </div>
      );
};

export default faqs;
