// SiteLogo.js
import React from 'react';

const SiteLogo = () => {
  return (
    <div className="container">
      <div className="columns is-centered">
        <div className="column has-text-centered">
          <img src="fltnr-full.png" alt="Site Logo" />
        </div>
      </div>
    </div>
  );
};

export default SiteLogo;
