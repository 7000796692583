import { PDFDocument } from 'pdf-lib';

export async function flattenPdf(sourcePdfUint8Array) {
  const sourcePdf = await PDFDocument.load(sourcePdfUint8Array);
  const form = sourcePdf.getForm();

  // Flatten form fields
  if (form) {
    form.flatten({ updateFieldAppearances: true });
  }

  const destPdfBytes = await sourcePdf.save();
  return destPdfBytes;
}
