import React from 'react';

const Footer = () => {
    return (
        <footer className="footer has-text-centered">
          <div className="content">
            <p>© 2023 Fltnr. All rights reserved.</p>
          </div>
        </footer>
      );
};

export default Footer;
