import React from 'react';

const About = () => {
    return (
        <div className="container">
          <section className="section">
            <div className="content">
            <h1 className="title">Fltnr - Secure PDF Flattening Tool</h1>
  
            <h2 className="subtitle">What is Fltnr:</h2>
            <p>
              Fltnr is an innovative and user-friendly PDF flattening tool, crafted
              to simplify complex PDF documents for seamless sharing, printing, and
              navigation. Dealing with multi-layered PDFs containing annotations or
              interactive forms can be challenging. Fltnr resolves these issues by
              consolidating layers into a single flattened layer, delivering
              consistent appearance and functionality across a variety of platforms
              and devices.
            </p>
    
            <h2 className="subtitle">Key Features:</h2>
            <ul>
              <li>
                Secure client-side processing: Fltnr is designed with privacy and
                security in mind, ensuring that your sensitive documents are never
                saved on the server and always processed client-side.
              </li>
              <li>
                Intuitive drag-and-drop interface: Effortlessly import PDF files by
                dragging and dropping them into the app or browsing and selecting
                files manually.
              </li>
              <li>
                Optimized workflow: Fltnr's minimalist design streamlines the
                flattening process, making it as simple and efficient as possible.
              </li>
              <li>
                Document quality preservation: Fltnr retains the original quality
                and resolution of your PDF documents while reducing their
                complexity.
              </li>
              <li>
                Enhanced compatibility: Flattened PDFs offer better compatibility
                with various PDF readers, printers, and devices, providing a
                seamless experience for end-users.
              </li>
              <li>
                Fast and lightweight: Fltnr is built for speed, making it the ideal
                solution for users who need to flatten multiple PDFs in a short time
                frame.
              </li>
            </ul>
    
            <p>
              Fltnr is the ultimate tool for Remote Online Notaries, professionals,
              students, and anyone who frequently works with PDFs. Enhance your
              productivity and simplify your PDF workflow with Fltnr, the secure PDF
              flattening tool. Try it now and experience the difference for
              yourself!
            </p>
            </div>
          </section>
        </div>
      );
};

export default About;
