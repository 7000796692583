import React from 'react';
import PropTypes from 'prop-types';

const Message = ({ type, message, onClose }) => {
  const messageTypeClass = `notification ${type === 'error' ? 'is-danger' : 'is-primary'}`;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className={messageTypeClass}>
      <button className="delete" onClick={handleClose} aria-label="Close notification"></button>
      {message}
    </div>
  );
};

Message.propTypes = {
  type: PropTypes.oneOf(['info', 'error']).isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default Message;
