import React, { useState } from "react";

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  const handleToggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <nav
      className="navbar is-primary"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img src="fltnr-nav.png" alt="Fltnr"/>
        </a>
        <button 
        className={`navbar-burger${isActive ? ' is-active' : ''}`} 
        aria-label="menu" 
        aria-expanded="false"
        data-target="main-nav"
        onClick={handleToggleMenu}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div id="main-nav"  className={`navbar-menu${isActive ? ' is-active' : ''}`}>
        <div className="navbar-start">
          <a className="navbar-item" href="/">
            Home
          </a>
          <a className="navbar-item" href="/faqs">
            FAQs
          </a>
          <a className="navbar-item" href="/about">
            About
          </a>
        </div>
        <div className="navbar-end"></div>
      </div>
    </nav>
  );
};

export default Navbar;
